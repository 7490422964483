import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Torus from "@toruslabs/torus-embed";
import Authereum from "authereum";

import { Bitski } from "bitski";

import Fortmatic from "fortmatic";
// eslint-disable-next-line global-require
const { USDT_ADDRESS } =
  process.env.NODE_ENV === "production" ? require("./constants") : require("./constants.dev");
//
const providerOptions = {
  // https://docs.walletconnect.org/
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "f0fd1047ce8742fdb72ae697111b9d64", // TODO infuraId
    },
  },
  // walletlink: {
  //   package: CoinbaseWalletSDK, // Required
  //   options: {
  //     appName: "My Awesome App", // Required
  //     infuraId: "f0fd1047ce8742fdb72ae697111b9d64", // Required
  //     //rpc: "", // Optional if `infuraId` is provided; otherwise it's required
  //     //chainId: 1, // Optional. It defaults to 1 if not provided
  //     //darkMode: false, // Optional. Use dark theme, defaults to false
  //   },
  // },
  // "custom-walletlink": {
  //   display: {
  //     logo: "https://play-lh.googleusercontent.com/wrgUujbq5kbn4Wd4tzyhQnxOXkjiGqq39N4zBvCHmxpIiKcZw_Pb065KTWWlnoejsg",
  //     name: "Coinbase",
  //     description: "Connect to Coinbase Wallet (V1)",
  //   },
  //   options: {
  //     appName: "Coinbase", // Your app name
  //     networkUrl: `https://mainnet.infura.io/v3/f0fd1047ce8742fdb72ae697111b9d64`,
  //     chainId: 1,
  //   },
  //   package: CoinbaseWalletSDK,
  //   connector: async (_, options) => {
  //     const { appName, networkUrl, chainId } = options;
  //     const walletLink = new CoinbaseWalletSDK({
  //       appName,
  //     });
  //     const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
  //     await provider.enable();
  //     return provider;
  //   },
  // },
  walletlink: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "My Awesome App", // Required
      infuraId: "f0fd1047ce8742fdb72ae697111b9d64", // Required
      //rpc: "", // Optional if `infuraId` is provided; otherwise it's required
      //chainId: 1, // Optional. It defaults to 1 if not provided
      //darkMode: false, // Optional. Use dark theme, defaults to false
    },
  },

  // https://github.com/torusresearch/torus-embed#readme
  // torus: {
  //   package: Torus,
  // },
  // // https://docs.fortmatic.com/
  // fortmatic: {
  //   package: Fortmatic,
  //   options: {
  //     key: "xxxxxxxxxx", // TODO key
  //   },
  // },
  // // https://docs.authereum.com/integration
  // authereum: {
  //   package: Authereum,
  // },
  // // https://docs.bitski.com/
  // bitski: {
  //   package: Bitski,
  //   options: {
  //     clientId: "xxxxxxxxxx", // TODO
  //     callbackUrl: `${window.location.href}bitski-callback.html`,
  //   },
  // },
};

export { USDT_ADDRESS, providerOptions };
